.MainWrapper {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  align-items: flex-start;
  .sideWrapper {
    background-color: #cfcfcf;
    background-image: url(../../Assets/images/bg.jpg);
    width: 80%;
    height: 100vh;
    overflow-y: auto;
    padding: 46px 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.loadingWrapper {
  display: flex;
  width: 100%;
  min-height: 100%;
  align-items: center;
  font-size: 1.5rem;
  justify-content: center;
  color: #fff;
}
