.uploaderWrapper {
  border: 8px;

  width: 100%;
  border-radius: 10px;
  padding-bottom: 1rem;
  background-color: #fff;
  margin-bottom: 3rem;
  .prevImg {
    width: 70vw;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .loader {
    width: 90%;
    margin-left: 1rem;
    align-self: center;
    height: 0.5rem;
    border-radius: 10px;
    background: rgba(92, 92, 92, 0.39);
    .progress {
      height: 100%;
      border-radius: 10px;
      background-color: #1425ff;
    }
  }
  .highlight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 94px;
    background-color: #fbfbfb;
    border-radius: 10px;
    padding: 10px 20px;
    .highlightText {
      font-size: 20px;
      font-weight: 400;
      color: rgba(56, 56, 56, 0.7);
      .name {
        font-size: 25px;
        font-weight: 500;
        color: #1425ff;
      }
      .location {
        color: #63b5e2;
      }
    }
    .highlightBtns {
      display: flex;
      gap: 2rem;
      .info {
        width: 30px;
        object-fit: contain;
        cursor: pointer;
      }

      .highlightBtn1 {
        height: 59px;
        width: 138px;
        background-color: #1425ff;
        color: #fff;
        border-radius: 50px;
        font-size: 1.5rem;
        display: grid;
        place-items: center;
        cursor: pointer;
      }
      .highlightBtn2 {
        height: 59px;
        width: 138px;
        color: #fff;
        border-radius: 50px;
        display: grid;
        place-items: center;
        cursor: pointer;
        background-color: #797979;
      }
    }
  }
  .banner {
    font-size: 1.5rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .banner1Btns {
    display: flex;
    color: rgba(56, 56, 56, 0.7);
    gap: 1rem;
    align-items: flex-end;
    width: 100%;
    padding: 10px;

    .detailLink {
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
    .bannerImg {
      width: min(40%, 200px);
      height: 150px;
      display: grid;
      color: #1425ff;
      place-content: center;
      background: #e8f2f8;
      border-radius: 5px;
      .uploadedImg {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .banner1BtnsWrapper {
      display: flex;
      gap: 1rem;
      align-items: flex-end;
      .banner1Btn {
        display: flex;
        align-items: center;
        color: #fff;
        justify-content: space-evenly;
        background: #1425ff;
        font-size: 20px;
        border-radius: 10px;
        min-width: 149.49px;
        padding: 20px;
        border-radius: 50px;
        height: 59px;
        cursor: pointer;
      }
      .bannerText {
        font-size: 20px;
        margin-bottom: 10px;
        color: #63b5e2;
      }
    }
  }
}
