.dualUploadWrapper {
  font-size: 25px;
  font-weight: 400;
  .dualUploadContainer {
    margin-top: 30px;
    width: 100%;
    min-height: 341px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 1px 0px 10px -2px rgba(0, 0, 0, 0.54);
    -moz-box-shadow: 1px 0px 10px -2px rgba(0, 0, 0, 0.54);
    box-shadow: 1px 0px 10px -2px rgba(0, 0, 0, 0.54);
    .prevImg {
      width: 70vw;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
    .successImg {
      width: 200px;
      height: 200px;
      margin-bottom: 50px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .highlight {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 94px;
      background-color: #fbfbfb;
      border-radius: 10px;
      padding: 10px 20px;
      .highlightText {
        font-size: 20px;
        font-weight: 400;
        color: rgba(56, 56, 56, 0.7);
        .name {
          font-size: 25px;
          font-weight: 500;
          color: #1425ff;
        }
        .location {
          color: #63b5e2;
        }
      }

      .highlightBtns {
        display: flex;
        gap: 2rem;
        .info {
          width: 30px;
          object-fit: contain;
          cursor: pointer;
        }

        .highlightBtn1 {
          height: 59px;
          width: 138px;
          color: #fff;
          border-radius: 50px;
          display: grid;
          place-items: center;
          cursor: pointer;
        }
        .highlightBtn2 {
          height: 59px;
          width: 138px;
          color: #fff;
          border-radius: 50px;
          display: grid;
          place-items: center;
          cursor: pointer;
          background-color: #797979;
        }
      }
    }

    .banners {
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr 1fr;
      color: #000;
      padding: 20px;
      min-height: 247px;
      border-radius: 10px;

      .banner {
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #1425ff;
        .loader {
          width: 90%;
          align-self: center;
          height: 0.5rem;
          border-radius: 10px;
          background: rgba(92, 92, 92, 0.39);
          .progress {
            height: 100%;
            border-radius: 10px;
            background-color: #1425ff;
          }
        }

        .headerWrapper {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 2rem;
          .checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1rem;
          }
        }
        .addUrl {
          width: 90%;

          border: none;
          background-color: transparent;
          font-size: 1.2rem;
          padding: 1rem;
          margin: 1rem;
          color: #1425ff;
          margin-bottom: 2rem;
          border-bottom: 1px solid #63b5e2;
          &::placeholder {
            color: #1425ff;
          }
          &:focus {
            outline: none;
          }
        }

        .banner1Btns {
          display: flex;
          color: rgba(56, 56, 56, 0.7);
          gap: 1rem;
          align-items: flex-end;
          width: 100%;
          border-right: 1px solid #d7d7d7;
          padding: 0 10px;

          .detailLink {
            width: 150px;
            height: 150px;
            object-fit: contain;
          }
          .bannerImg {
            width: min(40%, 200px);
            height: 150px;
            display: grid;
            color: #1425ff;
            place-content: center;
            background: #e8f2f8;
            border-radius: 5px;
            .uploadedImg {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .banner1BtnsWrapper {
            display: flex;
            gap: 1rem;
            align-items: flex-end;
            .banner1Btn {
              display: flex;
              align-items: center;
              color: #fff;
              justify-content: space-evenly;
              background: #1425ff;
              font-size: 20px;
              border-radius: 10px;
              min-width: 149.49px;
              padding: 20px;
              border-radius: 50px;
              height: 59px;
              cursor: pointer;
            }
            .bannerText {
              font-size: 20px;
              margin-bottom: 10px;
              color: #63b5e2;
            }
          }
        }
      }
      .banner {
        font-size: 20px;
        // padding-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #63b5e2;
        .banner2SubText {
          color: rgba(56, 56, 56, 0.7);
          display: flex;
          gap: 1rem;
          align-items: flex-end;

          .pdfViewer {
            background: #797979;
            padding: 0 0.5rem;
            display: grid;
            align-items: center;
            place-content: center;
            border-radius: 10px;
            min-height: 59px;
            min-width: 59px;
            width: 59px;
            border-radius: 50%;
            border-radius: 50%;
            margin-right: 1.5rem;
            cursor: pointer;

            text-decoration: none;
            color: #fff;

            font-size: 0.8rem;
            .preview {
              display: none;
            }
            &:hover {
              background: #dd782d;
            }
            &:hover > svg {
              display: none;
            }
            &:hover > .preview {
              display: block;
            }
            svg {
              height: 1.5rem;
            }
          }
          .banner2Btn {
            display: flex;
            align-items: center;
            color: #fff;
            justify-content: space-evenly;
            background: #797979;
            font-size: 20px;
            border-radius: 10px;
            min-width: 180px;
            padding: 20px;
            border-radius: 50px;
            height: 59px;
            cursor: pointer;
          }
          .banner2Text {
            max-width: 300px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1360px) {
  .dualUploadWrapper {
    font-size: 18px;
    .dualUploadContainer {
      .highlight {
        .highlightText {
          font-size: 16px;
        }
      }
      .banners {
        .banner1 {
          font-size: 16px;
          .banner1Btns {
            .banner1BtnsWrapper {
              flex-direction: column;
              align-items: flex-start;
              .banner1Btn {
                font-size: 16px;
              }
              .bannerText {
                font-size: 16px;
              }
            }
          }
        }
        .banner2 {
          font-size: 16px;
          .banner2SubText {
            .banner2Btn {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
