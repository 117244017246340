body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

.toggle-switch input[type="checkbox"] {
  display: none;
}

.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #ccc;
  border-radius: 25px;
  top: 0;
  right: 0;
  z-index: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}

.modalWrapper {
  position: fixed;
  inset: 0;
  display: grid;
  place-content: center;
}

.modalOverlay {
  position: fixed;
  inset: 0;
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.24);
}

.loadingWrapper {
  background-image: url(./Assets/images/bg.jpg);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff
}

@keyframes ldio-kl09nexvvim {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.ldio-kl09nexvvim div {
  position: absolute;
  width: 37px;
  height: 37px;
  border: 5px solid #1425ff;
  border-top-color: transparent;
  border-radius: 50%;
}

.ldio-kl09nexvvim div {
  animation: ldio-kl09nexvvim 1s linear infinite;
  top: 50px;
  left: 50px
}

.loadingio-spinner-rolling-bp1r5hpx2pt {
  width: 91px;
  height: 91px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldio-kl09nexvvim {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.91);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldio-kl09nexvvim div {
  box-sizing: content-box;
}


.modal {
  min-width: 30vw;
  min-height: 20vw;
  background-color: #fff;
  z-index: 25;
  display: grid;
  place-content: center;
  border-radius: 10px;
  padding: 40px 20px;
  text-align: center;
}

.successImg {
  width: 200px;
  height: 200px;
  margin-bottom: 50px;

}

.successImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 2px;
  top: 2px;
  width: 21px;
  height: 21px;
  background-color: #aaa;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle-switch input[type="checkbox"]:checked+.switch::before {
  transform: translateX(25px);
  background-color: #6699cc;
}

.toggle-switch input[type="checkbox"]:checked+.switch {
  background-color: #336699;
}