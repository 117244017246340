.loginWrapper {
  background-image: url(../../Assets/images/bg.jpg);
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .loginBox {
    background-color: #fff;
    padding: 2rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    gap: 1rem;
    .header {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
    .inputWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 1rem;
      input {
        border: 1px solid #c4c4c4;
        padding: 1rem;
        border-radius: 6px;
        &:focus {
          outline: none;
        }
      }
    }
    .btn {
      padding: 1rem 1.5rem;
      border-radius: 8px;
      background-color: #1425ff;
      border: none;
      color: #fff;
      margin-top: 2rem;
      cursor: pointer;
    }
    .err {
      color: red;
      font-size: 0.8rem;
    }
  }
}
