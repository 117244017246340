.navWrapper {
  width: 20%;
  min-height: 100vh;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  .logo {
    width: 150px;
    height: 150px;
    object-fit: contain;
  }
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    li {
      padding: 1rem 2rem;
      font-size: 1.3rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .signout {
    font-size: 1.3rem;
    margin-top: auto;
    margin-bottom: 2rem;
    cursor: pointer;
    background-color: #1425ff;
    padding: 1rem 1.5rem;
    border-radius: 6px;
  }
  .listHeader {
    margin-top: 30px;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 600;
    color: rgba(56, 56, 56, 0.8);
    cursor: pointer;
  }
  .listItem {
    font-size: 20px;
    font-weight: 400;
    color: rgba(56, 56, 56, 0.7);
    cursor: pointer;
  }
}

@media screen and (max-width: 1360px) {
  .navWrapper {
    .listHeader {
      font-size: 16px;
    }
    .listItem {
      font-size: 14px;
    }
  }
}
